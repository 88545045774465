export default [
  {
    path: '/distributors/messages',
    name: 'distributor-message-list',
    component: () => import('@/modules/distributor/views/distributor-message/DistributorMessageList.vue'),
    meta: {
      layout: 'content',
    },
  },
]
