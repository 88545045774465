<template>
  <v-icon class="ml-2">
    <template v-if="nature === 'tangible'">
      {{ icons.mdiTruck }}
    </template>
    <template v-else-if="nature === 'service'">
      {{ icons.mdiMeditation }}
    </template>
    <template v-else-if="nature === 'voucher'">
      {{ icons.mdiCash100 }}
    </template>
    <template v-else-if="nature === 'plan'">
      {{ icons.mdiClockOutline }}
    </template>
    <template v-else-if="nature === 'topup'">
      {{ icons.mdiArrowUp }}
    </template>
    <template v-else-if="nature === 'activity'">
      {{ icons.mdiAccountMultiple }}
    </template>
  </v-icon>
</template>

<script>
import {
mdiAccountMultiple,
mdiArrowUp,
mdiCash100, mdiClockOutline, mdiMeditation, mdiTruck,
} from '@mdi/js';

export default {
  props: {
    nature: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      icons: {
        mdiTruck,
        mdiMeditation,
        mdiCash100,
        mdiClockOutline,
        mdiArrowUp,
        mdiAccountMultiple,
      },
    }
  },
}
</script>
