export default [
  {
    path: '/common/tag-list',
    name: 'tag-list',
    component: () => import('@/modules/common/views/tag-list/TagList.vue'),
    meta: {
      layout: 'content',
    },
  },
]
