export default [
  {
    path: '/appointments',
    name: 'appointment-list',
    component: () => import('@/modules/product/views/appointment/AppointmentList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/appointment-history',
    name: 'appointment-history',
    component: () => import('@/modules/product/views/appointment/AppointmentHistory.vue'),
    meta: {
      layout: 'content',
    },
  },
]
