<template>
  <v-select
    v-model="currencyCode"
    placeholder="Select Currency"
    :items="currencyOptions"
    item-text="label"
    item-value="value"
    single-line
    outlined
    :dense="dense"
    :hide-details="hideDetails"
    :disabled="disabled"
  >
  </v-select>
</template>
<script>
import { useCurrency } from '@/composables'
import { ref, watch } from '@vue/composition-api'

export default {
  model: {
    prop: 'filters',
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, { emit }) {
    const { currencyOptions } = useCurrency()

    const currencyCode = ref(props.filters.currency)

    watch(currencyCode, currency => {
      emit('changed', {
        currency,
      })
    })

    return {
      currencyOptions,
      currencyCode,
    }
  },
}
</script>
