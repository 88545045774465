const merchant = [
  {
    path: '/merchants',
    name: 'list-merchants',
    component: () => import('@/modules/merchant/views/List.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/merchants/create',
    name: 'create-merchant',
    component: () => import('@/modules/merchant/views/Create.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/merchants/:id/overview',
    name: 'merchant-overview',
    component: () => import('@/modules/merchant/views/merchant-view/MerchantView.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default merchant
