import axios from '@axiosV2'

const index = params =>
  axios.get('/admin/countries', {
    params,
  })

const show = id => axios.get(`/admin/countries/${id}`)

const update = (id, data) => axios.put(`/admin/countries/${id}`, data)

export default {
  index,
  show,
  update,
}
