import appStore from '@/modules/app/store'

const fillLocales = (text = '', options = {}) => {
  const localeSuffix = options.locale_suffix || ''

  const results = {}
  const { locales } = appStore.state.info

  locales.forEach(locale => {
    results[locale] = text + (localeSuffix ? ` (${locale})` : '')
  })

  return results
}

export default function useTranslatable(field, name) {
  if (field && name && field[name]) {
    return field[name]
  }

  const isLocal = window.location.href.includes('localhost')

  return isLocal ? fillLocales('test dummy input', { locale_suffix: true }) : fillLocales()
}
