import axios from '@axios'

const fetchWebhookTypes = params => axios.get('/admin/webhook-types', { params })

const fetchChannelWebhooks = params => axios.get('/admin/channel-webhooks', { params })

const storeChannelWebhook = data => axios.post('/admin/channel-webhooks', data)

const updateChannelWebhook = (skuId, data) => axios.put(`/admin/channel-webhooks/${skuId}`, data)

const destroyChannelWebhook = skuId => axios.delete(`/admin/channel-webhooks/${skuId}`)

const fetchDistributorWebhooks = params => axios.get('/admin/distributor-webhooks', { params })

const storeDistributorWebhook = data => axios.post('/admin/distributor-webhooks', data)

const updateDistributorWebhook = (skuId, data) => axios.put(`/admin/distributor-webhooks/${skuId}`, data)

const destroyDistributorWebhook = skuId => axios.delete(`/admin/distributor-webhooks/${skuId}`)

export {
  destroyChannelWebhook,
  destroyDistributorWebhook,
  fetchChannelWebhooks,
  fetchDistributorWebhooks,
  fetchWebhookTypes,
  storeChannelWebhook,
  storeDistributorWebhook,
  updateChannelWebhook,
  updateDistributorWebhook,
}
