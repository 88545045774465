import { fetchWebhookTypes } from '@/api/webhook'

export default {
  namespaced: true,
  state: {
    webhookTypes: [],
  },
  getters: {},
  mutations: {
    SET_WEBHOOK_TYPES(state, webhookTypes) {
      state.webhookTypes = webhookTypes
    },
  },

  actions: {
    fetchAllWebhookTypes({ commit }) {
      return fetchWebhookTypes({ params: { take: 999 } }).then(response => {
        commit('SET_WEBHOOK_TYPES', response.data.data.records)
      })
    },
  },
}
