import { useParticipantRule } from '@/composables'
import { computed, ref } from '@vue/composition-api'
import { activityRuleService } from '../services'

export default () => {
  const activityRules = ref([])
  const { mapRule } = useParticipantRule()

  const activityRuleOptions = computed(() =>
    activityRules.value.map(m => {
      const pRule = mapRule(m.participant_rule).title

      return {
        label: `${m.main ? '(MAIN)' : ''} ${m.nicename} ${m.max_participant} - ${pRule}`,
        value: m.id,
      }
    }),
  )

  return {
    activityRules,
    activityRuleOptions,
    fetchActivityRules: query =>
      activityRuleService
        .index({
          take: 999,
          sort: '-main',
          ...query,
        })
        .then(({ records }) => {
          activityRules.value = records

          return activityRules.value
        }),
  }
}
