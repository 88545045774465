import { fetchAllCategories, fetchAllMethods, fetchAllTags, getCouriers } from '@/api/common'
import { fetchDiscountSchemes } from '@/api/order'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    categories: [],
    countries: [],
    tags: [],
    methods: [],
    languages: [],
    couriers: [],
    discountSchemes: [],
  },
  getters: {},
  mutations: {
    SET_CATEGORIES(state, cats = []) {
      state.categories = cats
    },
    SET_TAGS(state, tags = []) {
      state.tags = tags
    },
    SET_METHODS(state, methods = []) {
      state.methods = methods
    },
    SET_LANGUAGES(state, languages = []) {
      state.languages = languages
    },
    SET_COURIERS(state, couriers = []) {
      state.couriers = couriers
    },
    SET_DISCOUNT_SCHEMES(state, discountSchemes = []) {
      state.discountSchemes = discountSchemes
    },
  },
  actions: {
    fetchAllCategories({ commit }, queryParams) {
      return fetchAllCategories({ ...queryParams, take: 999 }).then(res => {
        commit('SET_CATEGORIES', res.data.data.records)

        return res
      })
    },

    fetchAllTags({ commit }, queryParams) {
      return fetchAllTags({ ...queryParams, take: 999 }).then(res => {
        commit('SET_TAGS', res.data.data.records)

        return res
      })
    },

    fetchAllMethods({ commit }, queryParams) {
      return fetchAllMethods({ ...queryParams, take: 999 }).then(res => {
        commit('SET_METHODS', res.data.data.records)

        return res
      })
    },

    fetchAllLanguages({ commit }, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/languages', { params: { ...queryParams, take: 999 } })
          .then(response => {
            commit('SET_LANGUAGES', response.data.data.records)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    fetchCouriers({ commit }) {
      return getCouriers().then(res => {
        commit('SET_COURIERS', res.data.data.records)

        return res
      })
    },

    fetchAllDiscountSchemes({ commit }, queryParams) {
      return fetchDiscountSchemes({ ...queryParams, take: 999 }).then(res => {
        commit('SET_DISCOUNT_SCHEMES', res.data.data.records)

        return res
      })
    },
  },
}
