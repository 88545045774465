<template>
  <div>
    <v-data-table v-model="selectedRows" :headers="tableColumnHeaders" :items="items" :items-per-page="99" :show-select="selectable" hide-default-footer>
      <!-- name -->
      <template #[`item.name`]="{ item }">
        <div class="align-center">
          <router-link class="text--primary font-weight-semibold text-decoration-none" :to="{ name: 'product-view', params: { id: item.sku.product_id } }">
            {{ t(item.sku_snapshot.product_title) }}
          </router-link>
          |
          <router-link
            class="text--primary font-weight-semibold text-decoration-none"
            :to="{ name: 'product-view', params: { id: item.sku.product_id }, query: { tab: 'SKUS' } }"
          >
            {{ t(item.sku_snapshot.sku_name) }}
          </router-link>
        </div>
      </template>

      <!-- price -->
      <template #[`item.price`]="{ item }">
        <span>
          {{ item.price | price }}
        </span>
      </template>

      <!-- quantity -->
      <template #[`item.quantity`]="{ item }">
        <span>
          {{ item.quantity }}
        </span>
      </template>

      <!-- subtotal -->
      <template #[`item.subtotal`]="{ item }">
        <span>
          {{ item.subtotal | price }}
        </span>
      </template>

      <!-- status -->
      <template #[`item.status`]="{ item }">
        <v-chip :color="['confirmed', 'completed'].includes(item.status) ? 'success' : ''" x-small>
          {{ item.status }}
        </v-chip>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'
import { t } from '@/plugins/i18n'
import { price } from '@core/utils/filter'

export default defineComponent({
  filters: { price },
  props: {
    selectable: {
      type: Boolean,
      required: false,
      default: false,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const selectedRows = ref([])

    const tableColumnHeaders = [
      { text: 'NAME', value: 'name', sortable: false },
      { text: 'UNIT PRICE', value: 'price', sortable: false },
      { text: 'QUANTITY', value: 'quantity', sortable: false },
      { text: 'TOTAL', value: 'subtotal', sortable: false },
      { text: 'STATUS', value: 'status', sortable: false },
      // { text: 'ACTION', value: 'action', sortable: false },
    ]

    watch(selectedRows, (newVal, OldValue) => {
      emit(
        'updated',
        newVal.map(item => item.id),
      )
    })

    const reset = () => {
      selectedRows.value = []
    }

    return {
      t,
      price,
      selectedRows,
      tableColumnHeaders,

      reset,
    }
  },
})
</script>

