import axios from '@axios'

const fetchDistributors = params => axios.get('/admin/distributors', { params })

const fetchDistributor = id => axios.get(`/admin/distributors/${id}`)

const storeDistributor = data => axios.post('/admin/distributors', data)

const updateDistributor = (id, data) => axios.put(`/admin/distributors/${id}`, data)

const destroyDistributor = id => axios.delete(`/admin/distributors/${id}`)

export {
    destroyDistributor, fetchDistributor, fetchDistributors, storeDistributor, updateDistributor
}

