import { mdiAlphaCBox, mdiTranslate } from '@mdi/js'

export default [
  {
    subheader: 'Channel Management',
  },
  {
    title: 'Channel',
    icon: mdiAlphaCBox,
    children: [
      {
        title: 'Channel',
        to: 'channel-list',
      },
      {
        title: 'Credentials',
        to: 'credential-list',
      },
      {
        title: 'Note List',
        to: 'channel-note-list',
      },
      {
        title: 'Selection List',
        to: 'selection-list',
      },
      {
        title: 'Dictionaries',
        icon: mdiTranslate,
        to: 'channel-dictionary-list',
      },
    ],
  },
]
