import { date } from '@/@core/utils/filter'
import { fetchModelTokens } from '@/api/common/model-token'
import { useTableOptions } from '@/composables'
import { computed, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default () => {
  const modelTokenListTable = ref([])

  const tableColumns = [
    { text: 'ID', value: 'id' },
    { text: 'CODE', value: 'code' },
    { text: 'ACTIVE', value: 'active' },
    { text: 'EXPIRES AT', value: 'expires_at' },
    { text: 'Notes', value: 'notes', sortable: false },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const options = ref(
    cached[0] || {
      sortBy: ['id'],
      sortDesc: [true],
      page: 1,
    },
  )
  const searchQuery = ref(cached[1] || '')
  const totalListTable = ref(0)
  const loading = ref(false)
  const totalLocal = ref([])
  const selectedRows = ref([])

  const tokenOptions = computed(() =>
    modelTokenListTable.value.map(token => ({
      title: `${token.code} until ${date(token.expires_at)} (${token.notes})`,
      value: token.code,
    })),
  )

  // fetch data
  const loadModelTokens = () =>
    fetchModelTokens(
      useTableOptions(options.value, {
        search: searchQuery.value,
      }),
    )
      .then(({ data }) => {
        const { records, pagination } = data.data

        modelTokenListTable.value = records
        totalListTable.value = pagination.total
        totalLocal.value = null

        // remove loading state
        loading.value = false
      })
      .catch(error => {
        console.log(error)
      })

  const cachable = [options, searchQuery]

  const cache = () => {
    cachable.forEach((variable, index) => {
      cached[index] = variable.value
    })
  }

  watch(
    cachable,
    debounce(() => {
      const newPage = options.value.page

      const hasDiff = cachable.find((variable, index) => index > 0 && cached[index] !== variable.value)

      if (newPage >= 2 && hasDiff) {
        options.value.page = 1
      }

      loading.value = true
      selectedRows.value = []
      loadModelTokens()
      cache()
    }, 300),
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    modelTokenListTable,
    tokenOptions,
    tableColumns,
    searchQuery,
    totalListTable,
    loading,
    options,
    totalLocal,
    selectedRows,
    loadModelTokens,
  }
}
