export default [
  {
    path: '/channel-webhook',
    name: 'channel-webhook',
    component: () => import('@/modules/webhook/views/ChannelWebhookList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/distributor-webhook',
    name: 'distributor-webhook',
    component: () => import('@/modules/webhook/views/DistributorWebhookList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/webhook-event-list',
    name: 'webhook-event-list',
    component: () => import('@/modules/webhook/views/WebhookEventList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/utility',
    name: 'utility',
    component: () => import('@/modules/utility/views/UtilityView.vue'),
    meta: {
      layout: 'content',
    },
  },
]
