import axios from '@axios'

const fetchAllCategories = params => axios.get('/admin/categories', { params })

const storeCategory = data => axios.post('/admin/categories', data)

const updateCategory = (id, data) => axios.put(`/admin/categories/${id}`, data)

const destroyCategory = id => axios.delete(`/admin/categories/${id}`)

const fetchAllMethods = params => axios.get('/admin/shipping-methods', { params })

const fetchAllTags = params => axios.get('/admin/tags', { params })

const storeTag = data => axios.post('/admin/tags', data)

const updateTag = (id, data) => axios.put(`/admin/tags/${id}`, data)

const destroyTag = id => axios.delete(`/admin/tags/${id}`)

const fetchAllOptionGroups = params => axios.get('/admin/option-groups', { params })

const storeOptionGroup = data => axios.post('/admin/option-groups', data)

const updateOptionGroup = (id, data) => axios.put(`/admin/option-groups/${id}`, data)

const destroyOptionGroup = id => axios.delete(`/admin/option-groups/${id}`)

const storeSeo = (instanceType, instanceId, data) => axios.post(`/admin/${instanceType}/${instanceId}/seos`, data)

const updateSeo = (instanceType, instanceId, id, data) => axios.put(`/admin/${instanceType}/${instanceId}/seos/${id}`, data)

const storePostMedia = (url, data) => axios.post(`/admin/post-media/${url}`, data)

const getCouriers = () => axios.get('/admin/couriers')

const getCities = () => axios.get('/v1/cities')

const getDistricts = () => axios.get('/v1/districts')

export {
  destroyCategory, destroyOptionGroup, destroyTag, fetchAllCategories, fetchAllMethods, fetchAllOptionGroups, fetchAllTags, getCities, getCouriers, getDistricts, storeCategory, storeOptionGroup, storePostMedia, storeSeo, storeTag, updateCategory, updateOptionGroup, updateSeo, updateTag
}

