// This file is to be refactored

const misc = [
  {
    path: '/apps/calendar',
    name: 'apps-calendar',
    component: () => import('@/@core/views/apps/calendar/Calendar.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/apps/invoice/list',
    name: 'apps-invoice-list',
    component: () => import('@/@core/views/apps/invoice/invoice-list/InvoiceList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/invoice/preview/:id',
    name: 'apps-invoice-preview',
    component: () => import('@/@core/views/apps/invoice/invoice-preview/InvoicePreview.vue'),
    meta: {
      layout: 'content',
    },
  },

  //
  //* ——— User ——————————————————
  //

  {
    path: '/user/channel-user/list',
    name: 'channel-user-list',
    component: () => import('@/modules/user/views/channel-user-list/UserList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/user/channel-user/view/:id',
    name: 'channel-user-view',
    component: () => import('@/modules/user/views/channel-user-view/UserView.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/user/quota/quota-group-list',
    name: 'quota-group-list',
    component: () => import('@/modules/user/views/quota/QuotaGroupList.vue'),
    meta: {
      layout: 'content',
    },
  },

  //
  //* ——— Channel ——————————————————
  //

  {
    path: '/channel/channel-list/channel-list',
    name: 'channel-list',
    component: () => import('@/modules/channel/views/channel-list/ChannelList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/channel/credential-list/credential-list',
    name: 'credential-list',
    component: () => import('@/modules/channel/views/credential-list/CredentialList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/channel/channel-note/list',
    name: 'channel-note-list',
    component: () => import('@/modules/channel/views/channel-note-list/ChannelNoteList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/channel/selection/selection-list',
    name: 'selection-list',
    component: () => import('@/modules/channel/views/selection/SelectionList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/channel/selection/selection-view/:id',
    name: 'selection-view',
    component: () => import('@/modules/channel/views/selection/SelectionView.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/channel/dictionary/list',
    name: 'channel-dictionary-list',
    component: () => import('@/modules/channel/views/dictionaries/DictionaryList.vue'),
    meta: {
      layout: 'content',
    },
  },

  //
  //* ——— Reporting ——————————————————
  //

  {
    path: '/reporting/channel-sales-report',
    name: 'channel-sales-report',
    component: () => import('@/modules/reporting/views/channel-sales-report/ChannelSalesOverview.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/reporting/channel-order-report',
    name: 'channel-order-report',
    component: () => import('@/modules/reporting/views/channel-order-report/ChannelOrderOverview.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/reporting/merchant-voucher-report',
    name: 'merchant-vouchers-report',
    component: () => import('@/modules/reporting/views/merchant-vouchers-report/MerchantVoucherOverview.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/reporting/merchant-service-report',
    name: 'merchant-services-report',
    component: () => import('@/modules/reporting/views/merchant-service-report/MerchantServiceOverview.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/reporting/merchant-tangible-report',
    name: 'merchant-tangibles-report',
    component: () => import('@/modules/reporting/views/merchant-tangible-report/MerchantTangibleOverview.vue'),
    meta: {
      layout: 'content',
    },
  },

  {
    path: '/reporting/distributor-export-report',
    name: 'distributor-export-report',
    component: () => import('@/modules/reporting/views/distributor-report/DistributoExportReport/index.vue'),
    meta: {
      layout: 'content',
    },
  },

  //
  //* ——— Chat ——————————————————
  //

  {
    path: '/apps/chat',
    name: 'apps-chat',
    component: () => import('@/@core/views/apps/chat/Chat.vue'),
    meta: {
      layout: 'content',
    },
  },

  //
  //* ——— Email ——————————————————
  //

  {
    path: '/apps/email',
    name: 'apps-email',
    component: () => import('@/@core/views/apps/email/Email.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/apps/email/:folder',
    name: 'apps-email-folder',
    component: () => import('@/@core/views/apps/email/Email.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['sent', 'draft', 'starred', 'spam', 'trash'].includes(to.params.folder)) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/apps/email/label/:label',
    name: 'apps-email-label',
    component: () => import('@/@core/views/apps/email/Email.vue'),
    meta: {
      layout: 'content',
      navActiveLink: 'apps-email',
    },
    beforeEnter(to, _, next) {
      if (['personal', 'company', 'important', 'private'].includes(to.params.label)) next()
      else next({ name: 'error-404' })
    },
  },
]

export default misc
