export default [
  {
    path: '/merchant-templates',
    name: 'merchant-template-list',
    component: () => import('../views/MerchantTemplateList.vue'),
    meta: {
      layout: 'content',
    },
  },
]
