import productStore from '@/modules/product/store'
import { computed } from '@vue/composition-api'

export default function useParticipantRule() {
  const mapRule = rule => {
    if (!rule.mandatory) {
      return { title: 'Not required', value: rule.id }
    }
    const reqs = []
    const opts = []

    Object.entries(rule.fields).forEach(([attr, value]) => {
      if (value === 'required') {
        reqs.push(attr)
      }
      if (value === 'optional') {
        opts.push(attr)
      }
    })

    return { title: `(Required: ${reqs.length}) ${reqs.join(', ')} | (Optional: ${opts.length}) ${opts.join(', ')}`, value: rule.id }
  }

  return {
    mapRule,
    participantRuleOptions: computed(() => productStore.state.participantRules.map(mapRule)),
  }
}
