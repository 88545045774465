export default [
  {
    path: '/common/model-token-list',
    name: 'model-token-list',
    component: () => import('@/modules/common/views/model-token/ModelTokenList.vue'),
    meta: {
      layout: 'content',
    },
  },
]
