<template>
  <v-autocomplete
    v-model="merchantId"
    :placeholder="$t('common.list.selectMerchant')"
    :items="merchantOptions"
    item-text="label"
    item-value="value"
    single-line
    outlined
    clearable
    hide-details
    :disabled="disabled"
  >
  </v-autocomplete>
</template>
<script>
import { useMerchant } from '@/composables';
import { ref, watch } from '@vue/composition-api';

export default {
  model: {
    prop: 'filters',
    event: 'changed',
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { merchantOptions, fetchMerchants } = useMerchant()

    fetchMerchants({ take: 999 })

    const merchantId = ref(props.filters.merchant_id)

    watch(merchantId, () => emit('changed', {
      ...props.filters,
      merchant_id: merchantId.value,
    }))

    return {
      merchantOptions,
      merchantId,
    }
  },
}
</script>
