// axios
import router from '@/router'
import axios from 'axios'
import Vue from 'vue'

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: process.env.NODE_ENV === 'development' ? '/' : process.env.VUE_APP_API_ENDPOINT,
  timeout: 60000, // generate report 3 months ~ 30s
  withCredentials: true,

  // headers: {'X-Custom-Header': 'foobar'}
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent
    const accessToken = Vue.$cookies.get('access_token')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response

    // Handle unauthenticated
    if (status === 401) {
      Vue.$cookies.remove('access_token')
      Vue.$cookies.remove('refresh_token')
      Vue.$cookies.remove('expires_in')

      return router.push({
        name: 'auth-login',
      })
    }

    return Promise.reject(error)
  },
)

Vue.prototype.$http = axiosIns

export default axiosIns
