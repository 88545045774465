const defaultState = {
  profile: null,
  ability: null,
}

export default {
  namespaced: true,
  state: { ...defaultState },
  getters: {
    getProfile(state) {
      return state.profile
    },

    getRole(state) {
      return state.profile?.role
    },

    getAbility(state) {
      return state.ability
    },
  },
  mutations: {
    SET_PROFILE(state, value) {
      state.profile = value
    },

    SET_ABILITY(state, value) {
      state.ability = value
    },

    RESET(state) {
      state.profile = defaultState.profile
      state.ability = defaultState.ability
    },
  },
  actions: {
    setProfile({ commit }, profile) {
      commit('SET_PROFILE', profile)
    },

    setAbility({ commit }, ability) {
      commit('SET_ABILITY', ability)
    },

    reset({ commit }) {
      commit('RESET')
    },
  },
}
