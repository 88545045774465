import { mdiCash100, mdiFileDocumentOutline } from '@mdi/js'

export default [
  {
    subheader: 'Order Management',
  },
  {
    title: 'Checkout',
    icon: mdiFileDocumentOutline,
    children: [
      {
        title: 'List',
        to: 'checkout-list',
      },
      {
        title: 'Orders',
        to: 'order-list',
      },
      {
        title: 'Memberships',
        to: 'membership-list',
      },
      {
        title: 'Refund Request',
        to: 'refund-request-list',
      },
      {
        title: 'Discount Scheme',
        to: 'discount-scheme-list',
      },
      {
        title: 'Scheme Items',
        to: 'scheme-item-list',
      },
      {
        title: 'Tax Scheme',
        to: 'tax-scheme-list',
      },

      // {
      //   title: 'Products',
      //   to: { name: 'apps-invoice-preview', params: { id: 4987 } },
      // },
    ],
  },
  {
    title: 'Coupon',
    icon: mdiCash100,
    children: [
      {
        title: 'Coupon',
        to: 'coupon-list',
      },
      {
        title: 'Coupon Codes',
        to: 'coupon-code-list',
      },
      {
        title: 'Saved User Codes',
        to: 'coupon-save-list',
      },
    ],
  },
]
