import axios from '@axiosV2'

const index = params =>
  axios.get('/admin/content-requests', {
    params,
  })

// const show = id => axios.get(`/admin/content-requests/${id}`)
// const store = data => axios.post('/admin/content-requests', data)
// const update = (id, data) => axios.put(`/admin/content-requests/${id}`, data)
// const destroy = id => axios.delete(`/admin/content-requests/${id}`)

export default {
  //   destroy,
  //   show,
  index,

  //   store,
  //   update,
}
