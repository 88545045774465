import axios from '@axios'

// const fetchUsers = queryParams => axios.get('/admin/channel-users', { params: queryParams })
const fetchUsers = queryParams => axios.get('/admin/channel-users', { params: queryParams })

const fetchUser = id => axios.get(`/admin/channel-users/${id}`)

const getUser = async () => {
  const { status, data } = await axios.get('/admin/me')

  if (status === 200) {
    return data.data
  }

  return null
}

export { fetchUser, fetchUsers, getUser }
