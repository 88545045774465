var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{attrs:{"headers":_vm.tableColumnHeaders,"items":_vm.items,"items-per-page":99,"show-select":_vm.selectable,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"align-center"},[_c('router-link',{staticClass:"text--primary font-weight-semibold text-decoration-none",attrs:{"to":{ name: 'product-view', params: { id: item.sku.product_id } }}},[_vm._v(" "+_vm._s(_vm.t(item.sku_snapshot.product_title))+" ")]),_vm._v(" | "),_c('router-link',{staticClass:"text--primary font-weight-semibold text-decoration-none",attrs:{"to":{ name: 'product-view', params: { id: item.sku.product_id }, query: { tab: 'SKUS' } }}},[_vm._v(" "+_vm._s(_vm.t(item.sku_snapshot.sku_name))+" ")])],1)]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("price")(item.price))+" ")])]}},{key:"item.quantity",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.quantity)+" ")])]}},{key:"item.subtotal",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(_vm._f("price")(item.subtotal))+" ")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"color":['confirmed', 'completed'].includes(item.status) ? 'success' : '',"x-small":""}},[_vm._v(" "+_vm._s(item.status)+" ")])]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }