import axios from '@axios'

const timeout = 120 * 1000 // 120s

const fetchOrders = params => axios.get('/admin/orders', { params })

const fetchOrder = id => axios.get(`/admin/orders/${id}`)

const updateOrder = id => axios.put(`/admin/orders/${id}`)

const exportOrders = (params = {}) =>
  axios
    .post(
      '/admin/orders/export.xlsx',
      { ...params, _method: 'GET' },
      {
        responseType: 'blob',
        timeout,
      },
    )
    .then(({ data }) => {
      const xlsxBlobUrl = window.URL.createObjectURL(new Blob([data], { type: 'application/vnd.ms-excel' }))
      window.open(xlsxBlobUrl)
    })

const updateOrderItemInventory = (id, data) => axios.put(`/admin/order-items/${id}/inventory`, data)

const fetchOrderCheckouts = params => axios.get('/admin/order-checkouts', { params })

const fetchOrderCheckout = id => axios.get(`/admin/order-checkouts/${id}`)

const notifyCheckout = (id, data) => axios.post(`/admin/order-checkouts/${id}/notify`, data)

const fetchCheckoutReceipts = id => axios.get(`/admin/order-checkouts/${id}/receipts`)

const resendCheckoutEvent = (id, type) => axios.post(`/admin/order-checkouts/${id}/resend-event/${type}`)

const downloadCheckoutReceiptPDF = id =>
  axios.get(`/admin/order-checkouts/${id}/receipts/download.pdf`, {
    responseType: 'blob',
  })

const confirmOrderItems = (id, ids) => axios.post(`/admin/orders/${id}/confirm`, { order_item_ids: ids })

const cancelOrder = (id, ids) => axios.delete(`/admin/orders/${id}/cancel`, { params: { order_item_ids: ids } })

const completeOrder = (id, ids) => axios.post(`/admin/orders/${id}/complete`, { order_item_ids: ids })

const readyForPickupOrder = (id, ids) => axios.post(`/admin/orders/${id}/ready-for-pickup`, { order_item_ids: ids })

const completePickupOrder = (id, ids) => axios.post(`/admin/orders/${id}/complete-pickup`, { order_item_ids: ids })

const fetchRefundRequests = params => axios.get('/admin/refund-requests', { params })

const fetchRefundRequest = id => axios.get(`/admin/refund-requests/${id}`)

const storeRefundRequest = data => axios.post('/admin/refund-requests', data)

const acceptRefundRequest = id => axios.post(`/admin/refund-requests/${id}/accept`)

const rejectRefundRequest = id => axios.post(`/admin/refund-requests/${id}/reject`)

const fetchCoupons = params => axios.get('/admin/coupons', { params })

const fetchCoupon = id => axios.get(`/admin/coupons/${id}`)

const storeCoupon = data => axios.post('/admin/coupons', data)

const updateCoupon = (id, data) => axios.put(`/admin/coupons/${id}`, data)

const destroyCoupon = id => axios.delete(`/admin/coupons/${id}`)

const storeCouponCodes = (id, data) => axios.post(`/admin/coupons/${id}/codes`, data)

const storeCouponSaves = (id, data) => axios.post(`/admin/coupon-codes/${id}/saves`, data)

const fetchCouponCodes = params => axios.get('/admin/coupon-codes', { params })

const destroyCouponCode = cid => axios.delete(`/admin/coupon-codes/${cid}`)

const fetchCouponSaves = params => axios.get('/admin/coupon-saves', { params })

const destroyCouponSave = cid => axios.delete(`/admin/coupon-saves/${cid}`)

const fetchDiscountSchemes = params => axios.get('/admin/discount-schemes', { params })

const fetchDiscountScheme = id => axios.get(`/admin/discount-schemes/${id}`)

const storeDiscountScheme = data => axios.post('/admin/discount-schemes', data)

const updateDiscountScheme = (id, data) => axios.put(`/admin/discount-schemes/${id}`, data)

const destroyDiscountScheme = id => axios.delete(`/admin/discount-schemes/${id}`)

const fetchTaxSchemes = params => axios.get('/admin/tax-schemes', { params })

// const fetchTaxScheme = id => axios.get(`/admin/tax-schemes/${id}`)

const storeTaxScheme = data => axios.post('/admin/tax-schemes', data)

const updateTaxScheme = (id, data) => axios.put(`/admin/tax-schemes/${id}`, data)

// const destroyTaxScheme = id => axios.delete(`/admin/tax-schemes/${id}`)

const storeSchemeItem = (data, path = 'category') => axios.post(`/admin/scheme-items/${path}`, data)

const updateSchemeItem = (id, data) => axios.put(`/admin/scheme-items/${id}`, data)

const fetchSchemeItems = params => axios.get('/admin/scheme-items', { params })

const destroySchemeItem = cid => axios.delete(`/admin/scheme-items/${cid}`)

export {
  acceptRefundRequest,
  cancelOrder,
  completeOrder,
  completePickupOrder,
  confirmOrderItems,
  destroyCoupon,
  destroyCouponCode,
  destroyCouponSave,
  destroyDiscountScheme,
  destroySchemeItem,
  downloadCheckoutReceiptPDF,
  exportOrders,
  fetchCheckoutReceipts,
  fetchCoupon,
  fetchCouponCodes,
  fetchCouponSaves,
  fetchCoupons,
  fetchDiscountScheme,
  fetchDiscountSchemes,
  fetchOrder,
  fetchOrderCheckout,
  fetchOrderCheckouts,
  fetchOrders,
  fetchRefundRequest,
  fetchRefundRequests,
  fetchSchemeItems,
  fetchTaxSchemes,
  notifyCheckout,
  readyForPickupOrder,
  rejectRefundRequest,
  resendCheckoutEvent,
  storeCoupon,
  storeCouponCodes,
  storeCouponSaves,
  storeDiscountScheme,
  storeRefundRequest,
  storeSchemeItem,
  storeTaxScheme,
  updateCoupon,
  updateDiscountScheme,
  updateOrder,
  updateOrderItemInventory,
  updateSchemeItem,
  updateTaxScheme,
}
