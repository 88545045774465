<template>
  <v-row class="mt-5">
    <v-col cols="4">
      <v-autocomplete
        v-model="cityId"
        :items="cityList"
        item-text="title"
        item-value="value"
        label="City"
        outlined
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="4">
      <v-autocomplete
        v-model="cityAreaId"
        :items="cityAreaList"
        item-text="title"
        item-value="value"
        label="Area"
        outlined
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="4">
      <v-autocomplete
        v-model="districtId"
        :items="districtList"
        item-text="title"
        item-value="value"
        label="District"
        outlined
      >
      </v-autocomplete>
    </v-col>
    <v-col cols="4">
      <v-autocomplete
        v-model="subDistrictId"
        :items="subDistrictList"
        item-text="title"
        item-value="value"
        label="Sub District"
        outlined
      >
      </v-autocomplete>
    </v-col>
  </v-row>
</template>

<script>
import { computed, onMounted, ref, watch } from '@vue/composition-api'
import { t } from '@/plugins/i18n'

export default {
  name: 'DistrictSelector',
  props: ['cities', 'value'],
  setup(props, { emit }) {
    const cityId = ref(null)
    const cityAreaId = ref(null)
    const districtId = ref(null)
    const subDistrictId = ref(null)

    const allCityAreas = ref([])
    const allDistricts = ref([])
    const allSubDistricts = ref([])

    const currentCity = computed(() => props.cities.find(d => d.id === cityId.value))
    const currentCityArea = computed(() => allCityAreas.value.find(d => d.id === cityAreaId.value))
    const currentDistrict = computed(() => allDistricts.value.find(d => d.id === districtId.value))
    const currentSubDistrict = computed(() => allSubDistricts.value.find(d => d.id === subDistrictId.value))

    const mapOptions = instance => ({ title: t(instance.name), value: instance.id })

    const cityList = computed(() => {
      const matchCities = props.cities.filter(city => city.id === (cityId.value || currentCityArea.value?.city_id)).map(mapOptions)

      return matchCities.length ? matchCities : props.cities.map(mapOptions)
    })
    const cityAreaList = computed(() => allCityAreas.value.filter(area => area.city_id === currentCity.value?.id).map(mapOptions))
    const districtList = computed(() => allDistricts.value.filter(district => district.city_area_id === currentCityArea.value?.id).map(mapOptions))
    const subDistrictList = computed(() => allSubDistricts.value.filter(subD => subD.parent_id === currentDistrict.value?.id).map(mapOptions))

    // const updateCityList = () => {
    //   cityList.value = props.cities.map(city => ({ name: city.name.en, id: city.id }))
    // }

    // watch(
    //   () => props.cities,
    //   () => {
    //     updateCityList()
    //   },
    // )

    watch([districtId, subDistrictId], () => {
      emit('changed', subDistrictId.value || districtId.value)
      emit('input', subDistrictId.value || districtId.value)
    })

    onMounted(() => {
      // updateCityList()
      props.cities.forEach(city => {
        allCityAreas.value = allCityAreas.value.concat(city.city_areas)
      })

      console.log('cityList', cityList.value)
      console.log('allCityAreas', allCityAreas.value)

      allCityAreas.value.forEach(area => {
        allDistricts.value = allDistricts.value.concat(area.districts)
      })

      console.log('allDistricts', allDistricts.value)

      allDistricts.value.forEach(district => {
        allSubDistricts.value = allSubDistricts.value.concat(district.sub_districts)
      })

      console.log('allSubDistricts', allSubDistricts.value)

      if (props.value) {
        const subDistrict = allSubDistricts.value.find(v => v.id === props.value)
        const district = allDistricts.value.find(v => v.id === (subDistrict?.parent_id || props.value))
        const cityArea = allCityAreas.value.find(v => v.id === district?.city_area_id)
        const city = props.cities.find(v => v.id === cityArea?.city_id)

        subDistrictId.value = subDistrict?.id
        districtId.value = district?.id
        cityAreaId.value = cityArea?.id
        cityId.value = city?.id
      }
    })

    return {
      cityId,
      cityAreaId,
      districtId,
      subDistrictId,

      cityList,
      cityAreaList,
      districtList,
      subDistrictList,

      currentCity,
      currentCityArea,
      currentDistrict,
      currentSubDistrict,
    }
  },
}
</script>
