export default [
  {
    path: '/common/ability-list',
    name: 'ability-list',
    component: () => import('@/modules/common/views/ability/AbilityList.vue'),
    meta: {
      layout: 'content',
    },
  },
]
