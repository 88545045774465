import axios from '@axiosV2'

const store = data => axios.post('/admin/product-review-comments', data)
const update = (id, data) => axios.put(`/admin/product-review-comments/${id}`, data)
const destroy = id => axios.delete(`/admin/product-review-comments/${id}`)

export default {
  destroy,
  store,
  update,
}
