const checkout = [
  {
    path: '/checkouts',
    name: 'checkout-list',
    component: () => import('@/modules/order/views/checkout-list/CheckoutList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checkout/:id/overview',
    name: 'checkout-view',
    component: () => import('@/modules/order/views/checkout-view/CheckoutView.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checkout-orders',
    name: 'order-list',
    component: () => import('@/modules/order/views/order-list/OrderList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checkout-orders/:id/overview',
    name: 'checkout-order-view',
    component: () => import('@/modules/order/views/order-view/OrderView.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checkout/receipt-preview/:id',
    name: 'checkout-receipt-preview',
    component: () => import('@/modules/order/views/receipt-preview/ReceiptPreview.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checkout/membership-list',
    name: 'membership-list',
    component: () => import('@/modules/order/views/membership-list/MembershipList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checkout/memberships/:id/overview',
    name: 'checkout-membership-view',
    component: () => import('@/modules/order/views/membership-view/MembershipView.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checkout/refund-request-list',
    name: 'refund-request-list',
    component: () => import('@/modules/order/views/refund-request-list/RefundRequestList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checkout/coupon-list',
    name: 'coupon-list',
    component: () => import('@/modules/order/views/coupon-list/CouponList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checkout/coupon-code-list',
    name: 'coupon-code-list',
    component: () => import('@/modules/order/views/coupon-list/CouponCodeList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checkout/coupon-save-list',
    name: 'coupon-save-list',
    component: () => import('@/modules/order/views/coupon-list/CouponSaveList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checkout/discount-scheme-list',
    name: 'discount-scheme-list',
    component: () => import('@/modules/order/views/discount-scheme-list/DiscountSchemeList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checkout/scheme-item-list',
    name: 'scheme-item-list',
    component: () => import('@/modules/order/views/discount-scheme-list/SchemeItemList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/checkout/tax-scheme-list',
    name: 'tax-scheme-list',
    component: () => import('@/modules/order/views/TaxSchemeList.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default checkout
