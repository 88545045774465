<template>
  <div>
    <v-simple-table v-for="shipping in shippings">
      <template v-slot:default>
        <tbody>
          <tr>
            <td><strong>Recipient</strong></td>
            <td>{{ shipping.destination.first_name }} {{ shipping.destination.last_name }}</td>
          </tr>
          <tr>
            <td><strong>Address</strong></td>
            <td>{{ shipping.address }}</td>
          </tr>
          <tr>
            <td><strong>Remarks</strong></td>
            <td>{{ shipping.destination.remarks }}</td>
          </tr>
          <tr>
            <td><strong>Contact</strong></td>
            <td>{{ shipping.destination.contact_number }}</td>
          </tr>
          <tr>
            <td><strong>Fee Payment</strong></td>
            <td>{{ shipping.fee_payment }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'shipping-detail-table',
  props: {
    shippings: {
      type: Array,
      required: true,
    },
  },
  setup(props) {},
})
</script>
