import common from '@/modules/common/routes'
import merchant from '@/modules/merchant/routes'
import product from '@/modules/product/routes'
import { canNavigate } from '@/plugins/acl/routeProtection'
import store from '@/store'
import Vue from 'vue'
import VueRouter from 'vue-router'
import checkout from './checkout'
import dashboard from './dashboard'
import distributor from './distributor'
import misc from './misc'
import pages from './pages'
import userInterface from './user-interface'
import utility from './utility'

Vue.use(VueRouter)

const routes = [
  // ? We are redirecting to different pages based on role.
  // NOTE: Role is just for UI purposes. ACL is based on abilities.
  {
    path: '/',
    redirect: to => {
      const isAccessTokenExist = Vue.$cookies.get('access_token') !== null

      if (!isAccessTokenExist) {
        return {
          name: 'auth-login',
          query: to.query,
        }
      }

      return {
        name: 'auth-user',
        query: to.query,
      }
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/modules/app/views/Error404.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/modules/app/views/Login.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
      redirectIfLoggedIn: true,
    },
  },

  // Get user profile & ability and update ability for access control
  // User is logged in when access token was stored in cookie
  // Need to perform this action when it is the first time login or page refreshed
  {
    path: '/authenticate',
    name: 'auth-user',
    component: () => import('@/modules/app/views/Authenticate.vue'),
    meta: {
      layout: 'blank',
      resource: 'Public',
    },
  },

  // {
  //   path: '/register',
  //   name: 'auth-register',
  //   component: () => import('@/views/Register.vue'),
  //   meta: {
  //     layout: 'blank',
  //     resource: 'Public',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  // {
  //   path: '/forgot-password',
  //   name: 'auth-forgot-password',
  //   component: () => import('@/views/ForgotPassword.vue'),
  //   meta: {
  //     layout: 'blank',
  //     resource: 'Public',
  //     redirectIfLoggedIn: true,
  //   },
  // },
  ...product,
  ...dashboard,
  ...merchant,
  ...checkout,
  ...userInterface,
  ...misc,
  ...pages,
  ...distributor,
  ...utility,
  ...common,
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',

  // base: config.api.baseUrl,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

// ? Router Before Each hook: https://router.vuejs.org/guide/advanced/navigation-guards.html
router.beforeEach((to, _, next) => {
  const isAccessTokenExist = Vue.$cookies.get('access_token') !== null
  const isUserAbilitySet = store.getters['user/getAbility'] !== null

  // Non public routes
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isAccessTokenExist) {
      return next({
        name: 'auth-login',
        query: {
          // marketplace: to.query.marketplace,
          redirect: to.fullPath,
        },
      })
    }

    // Redirect user to authenticate page if user ability is not set in vuex store
    if (!isUserAbilitySet) {
      return next({
        name: 'auth-user',
        query: {
          redirect: to.fullPath,
        },
      })
    }

    // Eles, not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (isAccessTokenExist && to.meta.redirectIfLoggedIn) {
    return next('/')
  }

  return next()
})

export default router
