<template>
  <v-select
    v-model="field"
    :items="options"
    :disabled="disabled"
    label="Status"
    outlined
    item-value="value"
    item-text="title"
    clearable
  ></v-select>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'

export default {
  model: {
    prop: 'filters',
    event: 'changed',
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    only: { // only statuses from this list will be shown
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const field = ref(props.filters?.status)
    const options = computed(() => [
      { title: 'Pending', value: 'pending' },
      { title: 'Approved', value: 'approved' },
      { title: 'Published', value: 'published' },
      { title: 'Unpublished', value: 'unpublished' },
      { title: 'Rejected', value: 'rejected' },
    ].filter(option => props.only.includes(option.value)))

    watch(field, () => emit('changed', {
      ...props.filters,
      status: field.value,
    }))

    return {
      field,
      options,
    }
  },
}
</script>
