import { fetchAllAttributeTypes, fetchAllParticipantRules } from '@/api/product'
import axios from '@axios'
import unionBy from 'lodash/unionBy'

export default {
  namespaced: true,
  state: {
    brands: [],
    attributeTypes: [],
    attributeValues: [], // grouped as { attribute_type_id, records }
    allValues: [],
    features: [],
    participantRules: [],
    taxSchemes: [],
    natureOptions: [
      { title: 'Tangible', value: 'tangible' },
      { title: 'Service', value: 'service' },
      { title: 'Voucher', value: 'voucher' },
      { title: 'Topup', value: 'topup' },
      { title: 'Plan', value: 'plan' },
      { title: 'Activity', value: 'activity' },
    ],
  },
  getters: {},
  mutations: {
    SET_BRANDS(state, brands) {
      state.brands = brands
    },
    SET_FEATURES(state, features) {
      state.features = features
    },
    SET_TAX_SCHEMES(state, taxSchemes) {
      state.taxSchemes = taxSchemes
    },
    SET_ATTRIBUTE_TYPES(state, attributeTypes) {
      state.attributeTypes = attributeTypes
    },
    SET_ATTRIBUTE_VALUES(state, updates) {
      const values = state.attributeValues.find(v => v.attribute_type_id === updates.attribute_type_id)
      if (values) {
        values.records = updates.records
      } else {
        state.attributeValues.push(updates)
      }
      state.allValues = unionBy(updates.records, state.allValues, 'id')
    },
    SET_PARTICIPANT_RULES(state, rules = []) {
      state.participantRules = rules
    },
  },

  actions: {
    fetchAllBrands({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/brands', { params: { take: 999 } })
          .then(response => {
            commit('SET_BRANDS', response.data.data.records)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    fetchAllFeatures({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/features', { params: { take: 999 } })
          .then(response => {
            commit('SET_FEATURES', response.data.data.records)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    fetchAllTaxSchemes({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/tax-schemes', { params: { take: 999 } })
          .then(response => {
            commit('SET_TAX_SCHEMES', response.data.data.records)
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    fetchAllAttributeTypes({ commit }, queryParams) {
      const params = { ...queryParams }
      params.take = 999

      return fetchAllAttributeTypes(params).then(res => {
        commit('SET_ATTRIBUTE_TYPES', res.data.data.records)

        return res
      })
    },

    // there are too many values. you must group by
    fetchAttributeValuesByType({ commit }, params) {
      return axios.get('/admin/attribute-values', { params }).then(response => {
        commit('SET_ATTRIBUTE_VALUES', {
          attribute_type_id: params.attributeTypeId,
          records: response.data.data.records,
        })

        return Promise.resolve(response)
      })
    },

    fetchAllParticipantRules({ commit }, queryParams) {
      return fetchAllParticipantRules({ ...queryParams, take: 999 }).then(res => {
        commit('SET_PARTICIPANT_RULES', res.data.data.records)

        return res
      })
    },
  },
}
