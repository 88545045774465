import { mdiHammerWrench, mdiHook, mdiWebhook } from '@mdi/js'

export default [
  {
    subheader: 'Utility & Devs',
  },
  {
    title: 'Utility',
    icon: mdiHammerWrench,
    to: 'utility',
  },
  {
    title: 'Channel Webhook',
    icon: mdiWebhook,
    to: 'channel-webhook',
  },
  {
    title: 'Distributor Webhook',
    icon: mdiHook,
    to: 'distributor-webhook',
  },
  {
    title: 'Events',
    icon: mdiHook,
    to: 'webhook-event-list',
  },
]
