<template>
  <v-navigation-drawer
    :value="active"
    temporary
    touchless
    :right="!$vuetify.rtl"
    :width="$vuetify.breakpoint.smAndUp ? 380 : '100%'"
    app
    @input="(val) => $emit('aside', val)"
  >
    <v-card>
      <v-card-title>
        <slot name="title">
          Export with plugins (optional)
        </slot>
      </v-card-title>

      <v-card-text>
        <model-exporter-form
          :loading="loading"
          :errors="errors"
          :resource="currentQuery"
          :enabled-plugins="enabledPlugins"
          @submit="handleSubmit"
        />
      </v-card-text>
    </v-card>
  </v-navigation-drawer>
</template>

<script>
import { useExtractErrors } from '@/composables'
import { mdiClose } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import ModelExporterForm from './ModelExporterForm.vue'

export default {
  components: { ModelExporterForm },
  model: {
    prop: 'active',
    event: 'aside',
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    enabledPlugins: {
      type: [Boolean, Array],
      default: () => ['*'],
    },
    resource: {
      type: Object,
      required: true,
    },
    exportResource: {
      type: Function,
      required: true,
    },
  },
  setup(props, { emit }) {
    const currentQuery = computed(() => {
      const data = { ...props.resource }

      delete data.take
      delete data.page

      Object.entries(data).forEach(([key, value]) => {
        if (value === null) {
          delete data[key]
        }
      })

      return data
    })
    const loading = ref(false)
    const errors = ref([])

    const handleSubmit = async form => {
      loading.value = true

      console.log('submitting form', { ...form })

      const request = props.exportResource({ ...form })

      await request
        .then(async () => {
          emit('changed')
        })
        .catch(err => {
          errors.value = useExtractErrors(err)
        })
      loading.value = false
    }

    return {
      handleSubmit,
      loading,
      errors,
      currentQuery,

      icons: {
        mdiClose,
      },
    }
  },
}
</script>
