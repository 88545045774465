import { mdiAccountOutline } from '@mdi/js'

export default [
  {
    title: 'User',
    icon: mdiAccountOutline,
    children: [
      {
        title: 'User List',
        to: 'channel-user-list',
      },
      {
        title: 'Bought Quota',
        to: 'quota-group-list',
      },
    ],
  },
]
