import { fetchDistributors } from '@/api/channel/distributor'
import { computed, ref } from '@vue/composition-api'

export default () => {
  const distributors = ref([])

  const distributorOptions = computed(() =>
    distributors.value.map(d => ({
      label: `${d.slug} (${d.business_name})`,
      value: d.id,
    })),
  )

  return {
    distributors,
    distributorOptions,
    fetchDistributors: query =>
      fetchDistributors(query).then(({ data }) => {
        console.log('waht is query', query)
        distributors.value = data.data.records

        return distributors.value
      }),
  }
}
