export default [
  {
    path: '/common/category-list',
    name: 'category-list',
    component: () => import('@/modules/common/views/category-list/CategoryList.vue'),
    meta: {
      layout: 'content',
    },
  },
]
