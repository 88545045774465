export default [
  {
    path: '/common/model-schedule-list',
    name: 'model-schedule-list',
    component: () => import('@/modules/common/views/model-schedule/ModelScheduleList.vue'),
    meta: {
      layout: 'content',
    },
  },
]
