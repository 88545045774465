<template>
  <v-autocomplete
    label="Visible Channel"
    :items="channelOptions"
    item-text="title"
    item-value="value"
    :value="channel ? channel.id : null"
    outlined
    dense
    hide-details
    clearable
    style="width: 150px;"
    @change="setChannel"
  ></v-autocomplete>
</template>

<script>
import channelStore from '@/modules/channel/store';
import store from '@/store';

export default {
  setup() {
    // const channelOptions = computed(() => channelStore.state.channels.map(ch => ({ title: `${t(ch.name)} (${ch.code})`, value: ch.id })))
    const channelOptions = store.getters['channel/channelOptions']

    const setChannel = id => {
      store.dispatch(
        'channel/setChannel',
        channelStore.state.channels.find(ch => ch.id === id),
      )
    }

    return {
      channelOptions,
      setChannel,
      channel: channelStore.state.channel,
    }
  },
}
</script>
