import { mdiAlphaDBox } from '@mdi/js'

export default [
  {
    title: 'Distributor',
    icon: mdiAlphaDBox,
    children: [
      {
        title: 'Message',
        to: 'distributor-message-list',
      },
    ],
  },
]
