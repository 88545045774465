import axios from '@axiosV2'

const index = params =>
  axios.get('/admin/activity-rules', {
    params,
  })
const show = id => axios.get(`/admin/activity-rules/${id}`)
const store = data => axios.post('/admin/activity-rules', data)
const update = (id, data) => axios.put(`/admin/activity-rules/${id}`, data)
const destroy = id => axios.delete(`/admin/activity-rules/${id}`)

export default {
  destroy,
  show,
  index,
  store,
  update,
}
