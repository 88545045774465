import axios from '@axiosV2'

const index = params =>
  axios.get('/admin/merchant-templates', {
    params,
  })
const show = id => axios.get(`/admin/merchant-templates/${id}`)
const store = data => axios.post('/admin/merchant-templates', data)
const update = (id, data) => axios.put(`/admin/merchant-templates/${id}`, data)
const destroy = id => axios.delete(`/admin/merchant-templates/${id}`)

export default {
  destroy,
  show,
  index,
  store,
  update,
}
