import { mdiBookOpen, mdiCurrencyUsd } from '@mdi/js'

export default [
  {
    subheader: 'Reporting',
  },

  {
    title: 'Channel Report',
    icon: mdiCurrencyUsd,
    children: [
      {
        title: 'Sales',
        to: 'channel-sales-report',
      },
      {
        title: 'Orders',
        to: 'channel-order-report',
      },
    ],
  },

  {
    title: 'Merchant Report',
    icon: mdiBookOpen,
    children: [
      {
        title: 'Vouchers',
        to: 'merchant-vouchers-report',
      },
      {
        title: 'Services',
        to: 'merchant-services-report',
      },
      {
        title: 'Tangibles',
        to: 'merchant-tangibles-report',
      },
    ],
  },

  {
    title: 'Distributor Report',
    icon: mdiBookOpen,
    children: [
      {
        title: 'Test SAS Storage',
        to: 'distributor-export-report',
      },
    ],
  },
]
