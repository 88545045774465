export default [
  {
    path: '/common/option-group',
    name: 'option-group-list',
    component: () => import('@/modules/common/views/option-group/OptionGroupList.vue'),
    meta: {
      layout: 'content',
    },
  },
]
