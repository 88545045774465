import axios from '@axiosV2'

const index = params =>
  axios.get('/admin/user-quotas', {
    params,
  })

const listTimeSlots = (id, params) => axios.get(`/admin/user-quotas/${id}/time-slots`, { params })

export default {
  index,
  listTimeSlots,
}
