import axios from '@axiosV2'

const index = params =>
  axios.get('/admin/product-reviews', {
    params,
  })
const show = id => axios.get(`/admin/product-reviews/${id}`)
const store = data => axios.post('/admin/product-reviews', data)
const update = (id, data) => axios.put(`/admin/product-reviews/${id}`, data)
const destroy = id => axios.delete(`/admin/product-reviews/${id}`)
const approve = id => axios.post(`/admin/product-reviews/${id}/approve`)
const reject = id => axios.post(`/admin/product-reviews/${id}/reject`)
const unpublish = id => axios.post(`/admin/product-reviews/${id}/unpublish`)

export default {
  destroy,
  show,
  index,
  store,
  update,
  approve,
  reject,
  unpublish,
}
