<template>
  <v-autocomplete
    label="Visible Merchant"
    :items="merchantOptions"
    item-text="title"
    item-value="value"
    :value="merchant ? merchant.id : null"
    outlined
    dense
    hide-details
    clearable
    style="width: 100px;"
    @change="setMerchant"
  ></v-autocomplete>
</template>

<script>
import merchantStore from '@/modules/merchant/store';
import { t } from '@/plugins/i18n';
import store from '@/store';
import { computed } from '@vue/composition-api';

export default {
  setup() {
    const merchantOptions = computed(() => merchantStore.state.all.map(merchant => ({ title: `${t(merchant.name)} (${merchant.code})`, value: merchant.id })))

    const setMerchant = id => {
      const merchant = merchantStore.state.all.find(m => m.id === id)
      store.dispatch('merchant/setCurrent', merchant)
    }

    return {
      merchantOptions,
      setMerchant,
      merchant: merchantStore.state.current,
    }
  },
}
</script>
