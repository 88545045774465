import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import app from './modules/app/store'
import channel from './modules/channel/store'
import common from './modules/common/store'
import merchant from './modules/merchant/store'
import product from './modules/product/store'
import user from './modules/user/store'
import webhook from './modules/webhook/store'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    appConfig: appConfigStoreModule,
    app,
    user,
    common,
    channel,
    product,
    webhook,
    merchant,
  },
})
