export default [
  {
    path: '/product/activity-list',
    name: 'activity-list',
    component: () => import('@/modules/product/views/activity/ActivityList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/product/activity-rule-list',
    name: 'activity-rule-list',
    component: () => import('@/modules/product/views/activity/ActivityRuleList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/product/activity-rule-template-list',
    name: 'activity-rule-template-list',
    component: () => import('@/modules/product/views/activity/ActivityRuleTemplateList.vue'),
    meta: {
      layout: 'content',
    },
  },
]
