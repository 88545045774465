<template>
  <v-select
    v-model="field"
    :items="options"
    :disabled="disabled"
    label="Active"
    outlined
    item-value="value"
    item-text="title"
    clearable
  ></v-select>
</template>
<script>
import { computed, ref, watch } from '@vue/composition-api'

export default {
  model: {
    prop: 'filters',
    event: 'changed',
  },
  props: {
    filters: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const field = ref(props.filters?.active)
    const options = computed(() => [
      { title: 'Active', value: 1 },
      { title: 'Inactive', value: 0 },
    ])

    watch(field, () => emit('changed', {
      ...props.filters,
      active: field.value,
    }))

    return {
      field,
      options,
    }
  },
}
</script>
