import ability from './ability'
import category from './category'
import contentRequest from './contentRequest'
import country from './country'
import modelSchedule from './modelSchedule'
import modelToken from './modelToken'
import optionGroup from './optionGroup'
import tag from './tag'

export default [...ability, ...category, ...country, ...contentRequest, ...tag, ...modelSchedule, ...modelToken, ...optionGroup]
