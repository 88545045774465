import axios from '@axios'

const fetchAllNotes = queryParams => axios.get('/admin/channel-notes', { params: queryParams })

const storeNote = data => axios.post('/admin/channel-notes', data)

const updateNote = (id, data) => axios.put(`/admin/channel-notes/${id}`, data)

const destroyNote = id => axios.delete(`/admin/channel-notes/${id}`)

const fetchChannels = queryParams => axios.get('/admin/channels', { params: queryParams })

const updateChannel = (id, data) => axios.put(`/admin/channels/${id}`, data)

const createChannel = data => axios.post('/admin/channels', data)

const fetchCredentials = queryParams => axios.get('/admin/channel-credentials', { params: queryParams })

const updateCredential = (id, data) => axios.put(`/admin/channel-credentials/${id}`, data)

const createCredential = data => axios.post('/admin/channel-credentials', data)

const fetchDictionaries = queryParams => axios.get('/admin/dictionaries', { params: queryParams })

const storeDictionary = data => axios.post('/admin/dictionaries', data)

const updateDictionary = (id, data) => axios.put(`/admin/dictionaries/${id}`, data)

const destroyDictionary = id => axios.delete(`/admin/dictionaries/${id}`)

export {
  createChannel,
  createCredential,
  destroyDictionary,
  destroyNote,
  fetchAllNotes,
  fetchChannels,
  fetchCredentials,
  fetchDictionaries,
  storeDictionary,
  storeNote,
  updateChannel,
  updateCredential,
  updateDictionary,
  updateNote,
}
