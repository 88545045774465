import axios from '@axiosV2'

const index = params =>
  axios.get('/admin/user-quota-applications', {
    params,
  })

const complete = (id, data) => axios.post(`/admin/user-quota-applications/${id}/complete`, data)

const confirm = id => axios.post(`/admin/user-quota-applications/${id}/accept`)

const cancel = id => axios.post(`/admin/user-quota-applications/${id}/reject`)

const refund = id => axios.post(`/admin/user-quota-applications/${id}/refund`)

const reschedule = (id, data) => axios.put(`/admin/user-quota-applications/${id}/inventory`, data)

export default {
  index,
  complete,
  confirm,
  cancel,
  refund,
  reschedule,
}
