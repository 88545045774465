import { merchantService } from '@/modules/merchant/services'
import { t } from '@/plugins/i18n'
import { computed, ref } from '@vue/composition-api'

export default () => {
  const merchants = ref([])

  const merchantOptions = computed(() =>
    merchants.value.map(m => ({
      label: t(m.name),
      value: m.id,
    })),
  )

  return {
    merchants,
    merchantOptions,
    fetchMerchants: query =>
      merchantService.index(query).then(({ records }) => {
        merchants.value = records

        return merchants.value
      }),
  }
}
